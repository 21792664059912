@import "modules";
.sidebar { background: $color-primary;}
.sidebar-fixed .sidebar, .sidebar .sidebar-nav, .sidebar .nav {width:250px;}
.app-header .navbar-brand {width:500px;}
.sidebar-fixed .main, .app-footer {margin-left:250px!important;}
.sidebar .nav-dropdown-toggle::before {opacity:0!important;}
.anim { -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }

[data-link] {cursor: pointer;}
a { @extend .anim; }
.zoom {
    @extend .anim;
    &:hover {z-index:1000; transform: scale(1.1);}
}
.zoom-1:hover {
    @extend .anim;
    &:hover {z-index:1000; transform: scale(1.05);}
}.zoom-out {
    @extend .anim;
    &:hover {transform: scale(0.95);}
}

.bg-hover {
    @extend .anim;
    &:hover {background-color: #f4f4f4;}
}

.bg-color {border: solid 1px $color-primary;}

.bg-grey {background: #444; color:#FFF;}

.anim_slow {-webkit-transition: all 1s ease-in-out; -moz-transition: all 1s ease-in-out; -ms-transition: all 1s ease-in-out; -o-transition: all 1s ease-in-out; transition: all 1s ease-in-out;}

.square {
    width: 100%!important;
    padding-bottom: 100%!important;
    background-repeat: no-repeat!important; 
    background-position:center center!important;
    background-size: cover!important;
}

.sidebar-nav .nav-dropdown {
    &.open a .fa-angle-down {
        transform: rotate(180deg);
    }
}
.col-form-label {font-weight: 600; color: #666;}
.card-header {text-transform: uppercase; font-weight: 600; font-size: 16px;}

.modal-dialog {
    .input-group-text{ 
        border:0;
        background:transparent; 
    }
}

.noresize {
  resize: none;
}
.nav-tabslist {
    .nav-item:not(:first-child) {margin-left: 5px;}
    .nav-link {
        @extend .anim;
    }
}
.sidebar {
    .nav-dropdown {
        &.open {
            background:rgba(0, 0, 0, 0.2);
        }
        
        .nav-dropdown-items {
            .nav-link {
                padding-left:30px;
                
                &.active {
                    background:rgba(0, 0, 0, 0.2);
                }
                
                &:not(.nav-link-default):hover {
                    background:rgba(255, 255, 255, 0.2);
                }
            }
        }
    }
    .nav-link {        
        &.active {
            background:rgba(0, 0, 0, 0.2);
        }
        &.nav-link-default {
            background: rgba(0,0,0,0.6);
        }
        &:not(.nav-link-default):hover {
            background:rgba(255, 255, 255, 0.2);
        }
    }

  .nav-group {
    position:relative;
    .nav-add {
      position:absolute;
      top:0;
      right:0;
      width:47px;
      height:47px;
      color:white;
      text-decoration: none;

      &:hover {
        background-color:rgba(255,255,255,0.2);
      }
    }

    .dropdown-menu {
        &.dropdown-menu-right.show {
            left:-5px !important;
            box-shadow: 3px 3px 10px #222;
        }
    }
  }

  .nav-item-fixed {
    position:fixed !important;
    bottom:0;
    width:$sidebar-width;
  }
}

.font-300 {font-weight: 300;}

#galerie {
    .galerie-item {
        margin-bottom:15px;
    }

    .galerie-image {        
        .image-triangle-delete {
            cursor:pointer;
            border-right: 40px solid transparent;
            border-left: 40px solid #C00;
            border-bottom: 40px solid transparent;
            height: 0;
            width: 0;
            position: absolute;
            top:0px;
            left: 15px;
            z-index: 2;  
            &:hover {border-left: 40px solid #A00;}
            &:after {
                position: absolute;
                top:2px;
                right: 24px;
                color: #FFF;
                font-size: 14px;
                content: '\f1f8';
                font-weight: normal;        
                font-family: FontAwesome;
            }
        }
        
        .image-triangle-edit{
            cursor:pointer;
            border-left: 40px solid transparent;
            border-right: 40px solid #337ab7;
            border-bottom: 40px solid transparent;
            height: 0;
            width: 0;
            position: absolute;
            top:0px;
            right: 15px;
            z-index: 2;          
            &:hover {border-right: 40px solid #286090;}
            &:after {
                position: absolute;
                top:2px;
                left: 24px;
                color: #FFF;
                font-size: 14px;
                content: '\f044';
                font-weight: normal;        
                font-family: FontAwesome;
            }
        }
    }
    
    
}
